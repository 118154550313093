import { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { SiteMetadataQuery } from '../graphqlTypes'

export const useSiteMetadata = () => {
  const queryData = useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          titleShort
          description
          siteUrl
        }
      }
    }
  `)

  const siteMetadata = queryData.site?.siteMetadata

  return useMemo(
    () => ({
      title: siteMetadata?.title,
      titleShort: siteMetadata?.titleShort,
      description: siteMetadata?.description,
      siteUrl: siteMetadata?.siteUrl,
    }),
    [siteMetadata],
  )
}
