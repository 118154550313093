import * as React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { Box } from '@walltowall/calico'
import clsx from 'clsx'

import { useKeyPress } from '../hooks/useKeyPress'
import { useScrollDirection } from '../hooks/useScrollDirection'

import { BoundedBox } from './BoundedBox'
import { HamburgerIcon } from './HamburgerIcon'
import { Heading } from './Heading'
import { Link } from './Link'
import { Navigation } from './Navigation'

import { ReactComponent as AssetLogoSVG } from '../assets/logo.svg'

import * as styleRefs from './Header.treat'

const isBrowser = typeof window !== 'undefined'

export const Header = () => {
  const [navIsOpen, toggleNavIsOpen] = React.useReducer(
    (state) => !state,
    false,
  )

  const { isScrollingDown } = useScrollDirection(200)
  useKeyPress('Escape', () => navIsOpen && toggleNavIsOpen())

  React.useEffect(() => {
    if (!isBrowser) return

    const htmlEl = document?.documentElement
    if (!htmlEl) return

    htmlEl.style.overflowY = navIsOpen ? 'hidden' : 'auto'
  }, [navIsOpen])

  return (
    <Box as="header">
      <Box styles={{ height: '4.125rem' }} />
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          pointerEvents: 'none',
          overflow: 'auto',
          transitionDuration: 'normal',
          transitionTimingFunction: 'easeInOut',
          transitionProperty: 'transform',
        }}
        className={clsx({
          [styleRefs.hidden]: isScrollingDown,
        })}
      >
        <BoundedBox
          variant="header"
          styles={{
            backgroundColor: 'white',
            color: 'gray10',
            pointerEvents: 'auto',
          }}
        >
          <Box
            styles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'spaceBetween',
            }}
          >
            <Box
              styles={{
                display: 'grid',
                gridAutoFlow: 'column',
                gap: [2, 3],
                alignItems: 'center',
              }}
            >
              <Link href="https://www.walltowall.com" target={undefined}>
                <VisuallyHidden>Wall-to-Wall Studios</VisuallyHidden>
                <Box styles={{ width: 8.5 }}>
                  <AssetLogoSVG style={{ display: 'block' }} />
                </Box>
              </Link>
              <Heading
                level={1}
                variant="sansCondC"
                styles={{ textTransform: 'uppercase' }}
              >
                <Link href="/">Labs</Link>
              </Heading>
            </Box>
            <Box as="button" onClick={toggleNavIsOpen}>
              <VisuallyHidden>
                {navIsOpen ? 'Close' : 'Open'} navigation
              </VisuallyHidden>
              <HamburgerIcon isActive={navIsOpen} />
            </Box>
          </Box>
        </BoundedBox>
        <Navigation
          toggleIsOpen={toggleNavIsOpen}
          styles={{
            flexGrow: 1,
            pointerEvents: navIsOpen ? 'auto' : 'none',
            opacity: navIsOpen ? 100 : 0,
            transitionDuration: 'normal',
            transitionProperty: 'opacity',
            transitionTimingFunction: 'easeOut',
          }}
        />
      </Box>
    </Box>
  )
}
