import * as React from 'react'
import { useStyles } from 'react-treat'
import {
  Box,
  useBoxStyles,
  usePseudoBoxStyles,
  BoxProps,
} from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'

import * as styleRefs from './Navigation.treat'
import { LinkProps, Link } from './Link'
import clsx from 'clsx'
import { Text } from './Text'
import { Inline } from './Inline'
import { Icon, IconProps } from './Icon'

const variants = {
  primary: {
    textVariant: 'sansCond-caps-52',
  },
  secondary: {
    textVariant: 'sansCond-caps-18',
  },
} as const

type NavItemProps = {
  variant?: keyof typeof variants
  href: LinkProps['href']
  linkProps?: Omit<LinkProps, 'href'>
  children: React.ReactNode
}

const NavItem = ({
  variant: variantName = 'primary',
  href,
  linkProps,
  children,
}: NavItemProps) => {
  const variant = variants[variantName]

  const className = clsx(
    useBoxStyles({
      display: 'block',
      padding: 5,
      transitionProperty: 'backgroundColor',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles({ backgroundColor: 'yellow50' }, ':hover'),
    usePseudoBoxStyles({ backgroundColor: 'yellow50' }, ':focus'),
  )

  return (
    <Box as="li">
      <Link href={href} className={className} {...linkProps}>
        <Text variant={variant.textVariant} styles={{ textAlign: 'center' }}>
          {children}
        </Text>
      </Link>
    </Box>
  )
}

type SocialNavLinkProps = {
  href: LinkProps['href']
  iconName: IconProps['name']
  label: string
}

const IconNavLink = ({ href, iconName, label }: SocialNavLinkProps) => {
  const className = clsx(
    useBoxStyles({
      display: 'block',
      padding: 1,
      borderRadius: '0.4em',
      transitionProperty: 'backgroundColor',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles({ backgroundColor: 'yellow50' }, ':hover'),
    usePseudoBoxStyles({ backgroundColor: 'yellow50' }, ':focus'),
  )

  return (
    <Link href={href} className={className}>
      <VisuallyHidden>{label}</VisuallyHidden>
      <Icon name={iconName} styles={{ width: '1.875rem' }} />
    </Link>
  )
}

type NavigationProps = {
  toggleIsOpen: () => void
} & BoxProps

export const Navigation = ({ toggleIsOpen, ...props }: NavigationProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box
      as="nav"
      className={styles.background}
      {...props}
      styles={{
        display: 'flex',
        flexDirection: 'column',
        color: 'gray10',
        paddingBottom: [8, 10, 12],
        ...props.styles,
      }}
    >
      <Box as="ul">
        <NavItem
          href="https://www.walltowall.com/pages/featured"
          linkProps={{ target: undefined }}
        >
          Work
        </NavItem>
        <NavItem
          href="https://www.walltowall.com/pages/company"
          linkProps={{ target: undefined }}
        >
          About
        </NavItem>
        <NavItem
          href="https://www.walltowall.com/news_posts"
          linkProps={{ target: undefined }}
        >
          News
        </NavItem>
        <NavItem
          href="https://www.walltowall.com/pages/contact"
          linkProps={{ target: undefined }}
        >
          Contact
        </NavItem>
      </Box>
      <Box as="ul" styles={{ marginBottom: 4 }}>
        <NavItem
          variant="secondary"
          href="https://www.walltowall.com/pages/culture"
          linkProps={{ target: undefined }}
        >
          Culture
        </NavItem>
        <NavItem
          variant="secondary"
          href="/"
          linkProps={{ onClick: toggleIsOpen }}
        >
          Labs
        </NavItem>
        <NavItem variant="secondary" href="https://shop.walltowall.com/">
          Shop
        </NavItem>
        <NavItem
          variant="secondary"
          href="https://www.walltowall.com/pages/careers"
          linkProps={{ target: undefined }}
        >
          Careers
        </NavItem>
      </Box>
      <Inline variant="list" space={5} styles={{ alignSelf: 'center' }}>
        <IconNavLink
          iconName="facebook"
          label="Facebook"
          href="https://www.facebook.com/walltowallstudios"
        />
        <IconNavLink
          iconName="twitter"
          label="Twitter"
          href="https://twitter.com/walltowall"
        />
        <IconNavLink
          iconName="instagram"
          label="Instagram"
          href="http://instagram.com/walltowall"
        />
        <IconNavLink
          iconName="youtube"
          label="YouTube"
          href="https://www.youtube.com/user/WalltoWallStudios"
        />
      </Inline>
    </Box>
  )
}
