import * as React from 'react'
import {
  Box,
  BoxProps,
  BoxStylesProps,
  ResponsiveProp,
} from '@walltowall/calico'
import * as RA from 'fp-ts/ReadonlyArray'

import { normalizeResponsiveProp } from '../lib/normalizeResponsiveProp'

const defaultElement = 'div'

const variants = {
  base: {
    paddingLeft: [4, 5, 6],
    paddingRight: [4, 5, 6],
    paddingTop: [8, 10, 12],
    paddingBottom: [8, 10, 12],
  },
  header: {
    paddingLeft: [4, 5, 6],
    paddingRight: [4, 5, 6],
    paddingTop: 4,
    paddingBottom: 4,
  },
} as const

type BoundedBoxProps<E extends React.ElementType> = {
  children?: React.ReactNode
  variant?: keyof typeof variants
  innerMaxWidth?: BoxStylesProps['maxWidth']
  nextSharesBg?: ResponsiveProp<boolean>
} & BoxProps<E>

export const BoundedBox = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      children,
      variant: variantName = 'base',
      innerMaxWidth,
      nextSharesBg = false,
      ...props
    }: BoundedBoxProps<E>,
    ref: typeof props.ref,
  ) => {
    const variant = variants[variantName]

    const resolvedPaddingBottom = RA.zipWith(
      normalizeResponsiveProp(nextSharesBg),
      normalizeResponsiveProp(variant.paddingBottom),
      (a, b) => (a ? 0 : b),
    ) as BoxStylesProps['paddingBottom']

    return (
      <Box
        as={defaultElement}
        ref={ref}
        {...props}
        styles={{
          paddingLeft: variant.paddingLeft,
          paddingRight: variant.paddingRight,
          paddingTop: variant.paddingTop,
          paddingBottom: resolvedPaddingBottom,
          ...props.styles,
        }}
      >
        <Box
          styles={{
            maxWidth: innerMaxWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 'full',
          }}
        >
          {children}
        </Box>
      </Box>
    )
  },
)
