import * as React from 'react'
import { Box } from '@walltowall/calico'
import { Helmet } from 'react-helmet-async'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

import { useSiteMetadata } from '../hooks/useSiteMetadata'

import { Text } from './Text'
import { Header } from './Header'
import { Footer } from './Footer'

export type LayoutProps = {
  children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
      </Helmet>
      <SkipNavLink>
        <Text variant="serif-16-20">Skip to content</Text>
      </SkipNavLink>
      <Box styles={{ backgroundColor: 'white' }}>
        <Header />
        <Box
          as="main"
          styles={{
            backgroundColor: 'white',
            color: 'black',
            fontFamily: 'serif',
          }}
        >
          <SkipNavContent />
          {children}
        </Box>
      </Box>
      <Footer />
    </>
  )
}
