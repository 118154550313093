import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import * as styleRefs from './Text.treat'

const variantExtraStyles: Record<
  keyof typeof styleRefs.variants,
  BoxProps['styles']
> = {
  'serif-16': {
    fontFamily: 'serif',
  },
  'serif-16-20': {
    fontFamily: 'serif',
  },
  'serif-17-24': {
    fontFamily: 'serif',
  },
  'sans-16': {
    fontFamily: 'sans',
  },
  'sans-16-20': {
    fontFamily: 'sans',
  },
  'sansCond-14': {
    fontFamily: 'sansCond',
  },
  'sansCond-16-17': {
    fontFamily: 'sansCond',
    letterSpacing: 'small',
    textIndent: 'small',
  },
  'sansCond-18': {
    fontFamily: 'sansCond',
  },
  'sansCond-caps-18': {
    fontFamily: 'sansCond',
    letterSpacing: 'small',
    textTransform: 'uppercase',
  },
  'sansCond-caps-52': {
    fontFamily: 'sansCond',
    letterSpacing: 'small',
    textTransform: 'uppercase',
  },
  'mono-12-16': {
    fontFamily: 'mono',
  },
  code: {
    fontFamily: 'mono',
  },
  'mono-caps-12': {
    fontFamily: 'mono',
    textTransform: 'uppercase',
    letterSpacing: 'medium',
    textIndent: 'medium',
  },
  'mono-caps-12-16': {
    fontFamily: 'mono',
    textTransform: 'uppercase',
    letterSpacing: 'medium',
    textIndent: 'medium',
  },
}

type TextProps = {
  children?: React.ReactNode
  component?:
    | 'div'
    | 'p'
    | 'span'
    | 'strong'
    | 'em'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
  variant: keyof typeof styleRefs.variants
} & Omit<BoxProps, 'component'>

export const Text = ({
  component,
  children,
  variant,
  className,
  ...props
}: TextProps) => {
  const styles = useStyles(styleRefs)

  const variantClassName = clsx(styles.variants[variant], className)

  const extraStyles = variantExtraStyles[variant]

  return (
    <Box
      as={component}
      className={variantClassName}
      {...props}
      styles={{
        ...extraStyles,
        ...props.styles,
      }}
    >
      {children}
    </Box>
  )
}
