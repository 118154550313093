import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { ReactComponent as AssetIconFacebookSVG } from '../assets/icon-facebook.svg'
import { ReactComponent as AssetIconInstagramSVG } from '../assets/icon-instagram.svg'
import { ReactComponent as AssetIconTwitterSVG } from '../assets/icon-twitter.svg'
import { ReactComponent as AssetIconYoutubeSVG } from '../assets/icon-youtube.svg'
import { ReactComponent as AssetIconChevronLeftSVG } from '../assets/icon-chevron-left.svg'
import { ReactComponent as AssetIconChevronRightSVG } from '../assets/icon-chevron-right.svg'

const icons = {
  facebook: AssetIconFacebookSVG,
  instagram: AssetIconInstagramSVG,
  twitter: AssetIconTwitterSVG,
  youtube: AssetIconYoutubeSVG,
  chevronLeft: AssetIconChevronLeftSVG,
  chevronRight: AssetIconChevronRightSVG,
}

export type IconProps = {
  name: keyof typeof icons
  color?: NonNullable<BoxProps['styles']>['color']
} & BoxProps

export const Icon = ({ name, color, ...props }: IconProps) => {
  const SVG = icons[name]

  return (
    <Box {...props} styles={{ color, ...props.styles }}>
      <SVG style={{ display: 'block' }} />
    </Box>
  )
}
