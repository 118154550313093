import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

export type DividerProps = {
  color?: NonNullable<BoxProps['styles']>['backgroundColor']
} & BoxProps

export const Divider = ({ color = 'current', ...props }: DividerProps) => (
  <Box
    {...props}
    styles={{
      backgroundColor: color,
      height: '1px',
      ...props.styles,
    }}
  />
)
