import * as React from 'react'
import { Box, usePseudoBoxStyles, useBoxStyles } from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'
import clsx from 'clsx'

import { ButtonLink } from './ButtonLink'
import { Divider } from './Divider'
import { Icon } from './Icon'
import { Inline } from './Inline'
import { Link, LinkProps } from './Link'
import { Text } from './Text'

import { ReactComponent as AssetLogoSVG } from '../assets/logo.svg'

const YEAR = new Date().getFullYear()
const TAGLINES = [
  "Solving Today's Problems with Yesterday's Solutions, Tomorrow.",
  "And remember, there's never a fee until we bill you.",
  'Doing The Bare Maximum Since 1992.',
  'Making stuff up since 1992.',
  'We have computers.',
  'Pittsburgh and Honolulu (obviously).',
]

type NavItemProps = {
  href: LinkProps['href']
  linkProps?: Omit<LinkProps, 'href'>
  children: React.ReactNode
}

const NavItem = ({ href, linkProps, children }: NavItemProps) => {
  const focusStyles = { color: 'white' } as const

  const className = clsx(
    useBoxStyles({
      transitionProperty: 'color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles(focusStyles, 'hover'),
    usePseudoBoxStyles(focusStyles, 'focus'),
  )

  return (
    <Text variant="serif-16">
      <Link href={href} className={className} {...linkProps}>
        {children}
      </Link>
    </Text>
  )
}

export const Footer = () => {
  const { current: tagline } = React.useRef(
    TAGLINES[Math.floor(Math.random() * TAGLINES.length)],
  )

  return (
    <Box
      as="footer"
      styles={{
        backgroundColor: 'gray10',
        color: 'gray50',
        paddingTop: 16,
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: 6,
      }}
    >
      <Box styles={{ display: 'grid', justifyItems: 'center', gap: 7 }}>
        <Box styles={{ width: 15, color: 'white' }}>
          <AssetLogoSVG style={{ display: 'block' }} />
        </Box>
        <Text
          variant="sansCond-18"
          styles={{
            textAlign: 'center',
            textTransform: 'uppercase',
            letterSpacing: 'medium',
          }}
        >
          {tagline}
        </Text>
        <ButtonLink
          variant="outlined"
          href="https://www.walltowall.com/pages/contact"
          linkProps={{ target: undefined }}
        >
          Let's Collaborate
        </ButtonLink>
      </Box>
      <Divider styles={{ marginTop: 11, marginBottom: 6 }} />
      <Box
        styles={{
          display: 'grid',
          gridAutoFlow: ['row', 'row', 'column'],
          justifyItems: 'center',
          justifyContent: ['center', 'center', 'spaceBetween'],
          alignItems: 'center',
          gap: 6,
        }}
      >
        <Text variant="serif-16">
          &copy; {YEAR} Wall-to-Wall Studios. All Rights Reserved.
        </Text>
        <Inline
          variant="list"
          space={4}
          align={['center', 'end']}
          alignY="center"
        >
          <NavItem
            href="https://www.walltowall.com/pages/featured"
            linkProps={{ target: undefined }}
          >
            Work
          </NavItem>
          <NavItem
            href="https://www.walltowall.com/pages/company"
            linkProps={{ target: undefined }}
          >
            About
          </NavItem>
          <NavItem
            href="https://www.walltowall.com/news_posts"
            linkProps={{ target: undefined }}
          >
            News
          </NavItem>
          <NavItem
            href="https://www.walltowall.com/pages/contact"
            linkProps={{ target: undefined }}
          >
            Contact
          </NavItem>
          <NavItem
            href="https://www.walltowall.com/pages/culture"
            linkProps={{ target: undefined }}
          >
            Culture
          </NavItem>
          <NavItem href="/">Labs</NavItem>
          <NavItem href="https://shop.walltowall.com/">Shop</NavItem>
          <NavItem
            href="https://www.walltowall.com/pages/careers"
            linkProps={{ target: undefined }}
          >
            Careers
          </NavItem>
          <NavItem
            href="https://www.walltowall.com/pages/privacy-policy"
            linkProps={{ target: undefined }}
          >
            Privacy Policy
          </NavItem>
          <Inline variant="list" space={4} alignY="center">
            <NavItem href="https://www.facebook.com/walltowallstudios">
              <VisuallyHidden>Facebook</VisuallyHidden>
              <Icon name="facebook" styles={{ width: 5 }} />
            </NavItem>
            <NavItem href="https://twitter.com/walltowall">
              <VisuallyHidden>Twitter</VisuallyHidden>
              <Icon name="twitter" styles={{ width: 5 }} />
            </NavItem>
            <NavItem href="http://instagram.com/walltowall">
              <VisuallyHidden>Instagram</VisuallyHidden>
              <Icon name="instagram" styles={{ width: 5 }} />
            </NavItem>
            <NavItem href="https://www.youtube.com/user/WalltoWallStudios">
              <VisuallyHidden>YouTube</VisuallyHidden>
              <Icon name="youtube" styles={{ width: 5 }} />
            </NavItem>
          </Inline>
        </Inline>
      </Box>
    </Box>
  )
}
