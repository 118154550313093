import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { Text } from './Text'

const variants = {
  filled: {
    backgroundColor: 'gray10',
    backgroundColorFocus: 'gray50',
    color: 'white',
    borderWidth: 'none',
    borderColor: undefined,
  },
  outlined: {
    backgroundColor: 'gray10',
    backgroundColorFocus: 'gray50',
    color: 'white',
    borderWidth: '1px',
    borderColor: 'white',
  },
} as const

export type ButtonProps = {
  variant?: keyof typeof variants
} & BoxProps

export const Button = ({
  children,
  variant: variantName = 'filled',
  ...props
}: ButtonProps) => {
  const variant = variants[variantName]

  return (
    <Box
      as="button"
      {...props}
      styles={{
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 6,
        paddingRight: 6,
        display: 'inlineBlock',
        backgroundColor: variant.backgroundColor,
        color: variant.color,
        borderStyle: 'solid',
        borderWidth: variant.borderWidth,
        borderColor: variant.borderColor,
        transitionTimingFunction: 'easeOut',
        transitionDuration: 'normal',
        transitionProperty: 'backgroundColor',
        ...props.styles,
      }}
      focusStyles={{
        backgroundColor: variant.backgroundColorFocus,
      }}
      hoverStyles={{
        backgroundColor: variant.backgroundColorFocus,
      }}
    >
      <Text
        variant="sansCond-14"
        styles={{ textTransform: 'uppercase', letterSpacing: 'medium' }}
      >
        {children}
      </Text>
    </Box>
  )
}
