import * as React from 'react'

export const useKeyPress = (
  targetKey: string,
  callback: (event: KeyboardEvent) => void,
) => {
  React.useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.key === targetKey) callback(event)
    }

    window.addEventListener('keydown', keyHandler)

    return () => void window.removeEventListener('keydown', keyHandler)
  }, [targetKey, callback])
}
