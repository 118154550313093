import * as React from 'react'
import GatsbyLink from 'gatsby-link'
import {
  Link as SystemLink,
  LinkProps as SystemLinkProps,
} from '@walltowall/siamese'

const GatsbyLinkShim = ({ href, ...props }: { href: string }) => (
  <GatsbyLink to={href} {...props} />
)

export type LinkProps = Omit<SystemLinkProps<'a'>, 'as'>

export const Link = (props: LinkProps) => (
  <SystemLink routerLinkComponent={GatsbyLinkShim} {...props} />
)
