import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import * as styleRefs from './Heading.treat'

const levelComponentMap = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
} as const

const variantExtraStyles: Record<
  keyof typeof styleRefs.variants,
  BoxProps['styles']
> = {
  sansCondA: {
    fontFamily: 'sansCond',
    fontWeight: 'bold',
  },
  sansCondACaps: {
    fontFamily: 'sansCond',
    fontWeight: 'bold',
    letterSpacing: 'small',
    textTransform: 'uppercase',
  },
  sansCondB: {
    fontFamily: 'sansCond',
    fontWeight: 'bold',
  },
  sansCondC: {
    fontFamily: 'sansCond',
    fontWeight: 'bold',
    letterSpacing: 'small',
    textTransform: 'uppercase',
  },
}

type HeadingProps = {
  children?: React.ReactNode
  level?: 1 | 2 | 3 | 4 | 5 | 6
  variant: keyof typeof styleRefs.variants
} & BoxProps

export const Heading = ({
  children,
  level = 1,
  variant,
  className,
  ...props
}: HeadingProps) => {
  const styles = useStyles(styleRefs)

  const component = levelComponentMap[level]

  const variantClassName = clsx(styles.variants[variant], className)

  const extraStyles = variantExtraStyles[variant]

  return (
    <Box
      as={component}
      className={variantClassName}
      {...props}
      styles={{
        ...extraStyles,
        ...props.styles,
      }}
    >
      {children}
    </Box>
  )
}
